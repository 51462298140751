import React, {Fragment, useContext, useState} from 'react';
import Alert from "@meridian/components/alert";
import {FormattedMessage} from "react-intl";
import Column from "@meridian/components/column";
import Row from "@meridian/components/row";
import Text from "@meridian/components/text";
import Button from "@meridian/components/button";
import Divider from "@meridian/components/divider";
import Heading from "@meridian/components/heading";
import Masthead, {MastheadLink} from "@meridian/components/masthead";
import Modal, {ModalFooter} from "@meridian/components/modal";
import Box from "@meridian/components/box";
import Table, {TableCell, TableRow} from "@meridian/components/table";
import {NodeResourceContext} from "../../../context/NodeResourceContext";
import {
    BEGIN_CALL, BEGIN_BULK_EXCEL_PARSING,
    BULK_UPLOAD_RESOURCE,
    CLEAR_RESOURCE_DATAMAP,
    CONCLUDE_BULK_RESOURCE_CREATION_MODAL,
    END_CALL_FOR_DOWNLOAD_RESOURCES,
    READ_RESOURCE_FILE_FAILURE,
    READ_RESOURCE_FILE_SUCCESS,
    UPDATE_ACTIVE_TAB
} from "../../../reducers/NodeManagerReducer";
import {
    getEndpointBasedOnResourceType,
    getResourceDataMapHelper,
    getuploadTypeBasedOnResourceType,
    readResourceFile,
    renderHeaderMessage
} from "../../../helper/NodeResourceManagerHelper";
import {
    deprecateResource,
    getRADataForSSDStation,
    getSSPLaneDataForSSDStation,
    uploadResources
} from "../../../resources/NodeResourceManagerResource";
import {CapabilityContext} from "../../../context/CapabilityContext";
import {
    DEFAULT,
    EXCEL_TYPES,
    FILE_PATH,
    NodeResourcesAssociation_excel,
    SSD,
    STEM,
    NodeResources_excel,
    VINYAAS,
    TYPE,
    RESOURCE_TYPES_WITH_ADDITION_OPERATION_NOT_SUPPORTED,
    ALL_ATTRIBUTES,
    ITEMS_PER_PAGE_IN_PREVIEW,
    INACTIVE_STATUS_TYPE,
    ERROR_MAP,
    DEFAULT_FORMATTED_MESSAGES,
    LOADER_TYPE,
    DEFAULT_NUM_PAGES,
    DEFAULT_CURRENT_PAGE_INDEX,
    DEPRECATION_OPERATION_PARAMETERS,
    NodeResourcesOVDFeatureEnabled_excel,
    RESOURCE_TYPES,
    SSD_NodeResourcesAssociation_excel, SSD_V2
} from "../../../Constants";
import {chainWalk} from "../../util/CommonUtils";
import {getNodeId, getRegion, getStage} from "../../../Utility";
import Pagination from "@amzn/meridian/pagination"
import Loader from "@amzn/meridian/loader";
import * as XLSX from 'xlsx';

export default function CreateResourceModal() {
    const {capability} = useContext(CapabilityContext);
    const {state, dispatch} = useContext(NodeResourceContext);
    const {narc_sls_intergration_enabled, uploadType, loadingFile, loaderType, isOVDFeatureEnabled,
        isBulkUploadReaderUsingXLSXReaderEnabled, isResourceRevivalUsingAddBulkResourcesAPIEnabled, isSSDMMOTEnabled, isNARCSSDBinCreationEnabled} = state;
    const [numPagesInBulkUploadPreview, setNumPagesInBulkUploadPreview] = useState(DEFAULT_NUM_PAGES);
    const [currentPageIndexInBulkUploadPreview, setCurrentPageIndexInBulkUploadPreview] = useState(DEFAULT_CURRENT_PAGE_INDEX);

    const updateActiveTab = (resourceName) => {
        dispatch({type: UPDATE_ACTIVE_TAB, payload: {activeTab: resourceName}})
        // Pagination Support : Sets number of pages required in preview of excel file
        let numResources = resourceDataMap[resourceName].sheetMapList.length;
        setNumPagesInBulkUploadPreview(Math.ceil(numResources/ITEMS_PER_PAGE_IN_PREVIEW));
        // Pagination Support : Sets current page index to 1 on tab switch in preview of excel file
        setCurrentPageIndexInBulkUploadPreview(DEFAULT_CURRENT_PAGE_INDEX);
    }

    const concludeBulkResourceCreation = (resourceSearchCriteria, status) => {
        dispatch({type: CONCLUDE_BULK_RESOURCE_CREATION_MODAL, payload: {status: status}})
    }

    const displayFileData = (nodeId, resourceTypes, event, type_excel = '') => {
        dispatch({type: BEGIN_BULK_EXCEL_PARSING, payload : {loaderType : LOADER_TYPE.PREVIEW}});
        /**  SheetJS XLSX reader improves latency of parsing excel data from ~25sec to ~2-3sec
         * isBulkUploadReaderUsingXLSXReaderEnabled determines if flag is enabled to use SheetJS reader
        */
        if(isBulkUploadReaderUsingXLSXReaderEnabled) {
            readResourceFileUsingXLSXReader(nodeId, resourceTypes, event, type_excel);
        } else {
            readResourceFile(nodeId, resourceTypes, event, type_excel, narc_sls_intergration_enabled, isSSDMMOTEnabled).then(results => {
                if (results.error) {
                    dispatch({
                        type: READ_RESOURCE_FILE_FAILURE,
                        payload: {uploadBulkResourceError: results.uploadBulkResourceError}
                    })
                } else {
                    dispatch({
                        type: READ_RESOURCE_FILE_SUCCESS, payload: {
                            resourceDataMap: results.resourceDataMap, resourceNames: Object.keys(results.resourceDataMap),
                            activeTab: Object.keys(results.resourceDataMap)[0]
                        }
                    })
                }
            });
        }
    }
    
    /**
     * Parses excel data in bulk upload
     * @param {*} excelData 
     * @param {*} resourceTypes 
     * @param {*} type_excel 
     */
    const processExcelData = (excelData, resourceTypes, type_excel) => {
        //Read data from excel file, and transform into json format
        const workbook = XLSX.read(excelData, {type: 'binary'});
        let sheetRowsMap = {}
        workbook.SheetNames.forEach(sheetName => {
            sheetRowsMap[sheetName] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1, defval:null});
        });
        let resourceDataMap = getResourceDataMapHelper(sheetRowsMap, resourceTypes, type_excel, narc_sls_intergration_enabled, isSSDMMOTEnabled);

        // Check if data is read successfully
        if (Object.keys(resourceDataMap).includes("uploadBulkResourceError")) {
            dispatch({
                type: READ_RESOURCE_FILE_FAILURE,
                payload: {uploadBulkResourceError: resourceDataMap.uploadBulkResourceError}
            })
        } else if(Object.keys(resourceDataMap).length === 0) {
            dispatch({
                type: READ_RESOURCE_FILE_FAILURE,
                payload: {uploadBulkResourceError: ERROR_MAP.NO_RESOURCES_FOUND}
            })
        } else {
            dispatch({
                type: READ_RESOURCE_FILE_SUCCESS, payload: {
                    resourceDataMap: resourceDataMap, resourceNames: Object.keys(resourceDataMap),
                    activeTab: Object.keys(resourceDataMap)[0]
                }
                
            })
            let numResourcesInActiveTab = resourceDataMap[Object.keys(resourceDataMap)[0]].sheetMapList.length;
            setNumPagesInBulkUploadPreview(Math.ceil(numResourcesInActiveTab/ITEMS_PER_PAGE_IN_PREVIEW));
        }
    }
    
    /**
     * Reads resource file using XLSX Reader
     * @param {*} stationCode 
     * @param {*} resourceTypes 
     * @param {*} event 
     * @param {*} type_excel 
     */
    async function readResourceFileUsingXLSXReader(stationCode, resourceTypes, event, type_excel='') {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            processExcelData(reader.result, resourceTypes, type_excel);
        };
        reader.readAsBinaryString(file);
    }

    /**
     * Creates a mapping of resources which need to be created/revived/deprecated
     * @param nodeId
     * @param resourceDataMap
     * @returns {{resourcesToAdd: {}, resourcesToDeprecate: *[], resourcesToRevive: *[]}}
     */
    const fetchMappingOfResourcesAccordingToOperation = (nodeId, resourceDataMap, isOVDFeatureEnabled) => {
        let resourcesToAdd = {}, resourcesToRevive = [], resourcesToDeprecate = [];
        Object.keys(resourceDataMap).forEach((resourceType) => {
            let resourceList = resourceDataMap[resourceType];
            resourceList.sheetMapList.forEach(resource => {
                if( (isOVDFeatureEnabled && resourceType === RESOURCE_TYPES.BIN) || isResourceRevivalUsingAddBulkResourcesAPIEnabled) {
                    if (!Object.keys(resourcesToAdd).includes(resourceType)) {
                        resourcesToAdd[resourceType] = {"headerRows": resourceList.headerRows, "sheetMapList": []};
                    }
                    (resourcesToAdd[resourceType]["sheetMapList"]).push(resource);
                } else {
                    if (resource[ALL_ATTRIBUTES.Status].toUpperCase() === INACTIVE_STATUS_TYPE) {
                        resourcesToDeprecate.push({
                            nodeId: nodeId,
                            label: resource[ALL_ATTRIBUTES.Label],
                            resourceType: resourceType
                        })
                    } else if (!(RESOURCE_TYPES_WITH_ADDITION_OPERATION_NOT_SUPPORTED.includes(resourceType))) {
                        if (!Object.keys(resourcesToAdd).includes(resourceType)) {
                            resourcesToAdd[resourceType] = {"headerRows": resourceList.headerRows, "sheetMapList": []};
                        }
                        (resourcesToAdd[resourceType]["sheetMapList"]).push(resource);
                    } else if ((RESOURCE_TYPES_WITH_ADDITION_OPERATION_NOT_SUPPORTED.includes(resourceType))) {
                        resourcesToRevive.push({
                            nodeId: nodeId,
                            label: resource[ALL_ATTRIBUTES.Label],
                            resourceType: resourceType
                        })
                    }
            }
            })
        });
        return {
            resourcesToAdd : resourcesToAdd,
            resourcesToRevive : resourcesToRevive,
            resourcesToDeprecate : resourcesToDeprecate
        };
    }

    /**
     * Performs bulk upload for operation : Deprecation
     * @param {*} resourcesToDeprecate 
     * @param {*} uploadType 
     */
    const performBulkDeprecation = (resourcesToDeprecate, uploadType) => {
        deprecateResource(resourcesToDeprecate, DEPRECATION_OPERATION_PARAMETERS.DEPRECATION_FLAG.TRUE, 
            DEPRECATION_OPERATION_PARAMETERS.FORCE_FLAG.FALSE).then(result_deprecate => {
            // If deprecation succeeded, return success response
            if (result_deprecate.success) {
                dispatch({
                    type: BULK_UPLOAD_RESOURCE, payload: {
                        uploadBulkResourceSuccess: true,
                        uploadType: uploadType
                    }
                });
            } else {
                // If deprecation failed, return failure response
                dispatch({
                    type: BULK_UPLOAD_RESOURCE, payload: {
                        uploadBulkResourceSuccess: result_deprecate.success,
                        uploadBulkResourceError: result_deprecate.updateResourceError,
                        uploadType: uploadType
                    }
                });
            }
        })
    }

    /**
     * Performs bulk upload for operations : Deprecation/Revival
     * @param {*} resourcesToRevive 
     * @param {*} resourcesToDeprecate 
     * @param {*} uploadType 
     */
    const performBulkRevivalAndDeprecation = (resourcesToRevive, resourcesToDeprecate, uploadType) => {
        deprecateResource(resourcesToRevive, DEPRECATION_OPERATION_PARAMETERS.DEPRECATION_FLAG.FALSE, 
            DEPRECATION_OPERATION_PARAMETERS.FORCE_FLAG.TRUE).then(result_revive => {
            if (result_revive.success) {
                // If revival succeded, proceed for deprecation operation
                performBulkDeprecation(resourcesToDeprecate, uploadType);
            } else {
                // If revival failed, return failure response
                dispatch({
                    type: BULK_UPLOAD_RESOURCE, payload: {
                        uploadBulkResourceSuccess: result_revive.success,
                        uploadBulkResourceError: result_revive.updateResourceError,
                        uploadType: uploadType
                    }
                });
            }
        })
    }

    /**
     * Performs bulk upload for all operations : Addition/Deprecation/Revival
     * @param nodeId
     * @param resourceDataMap
     * @param narc_sls_intergration_enabled
     * @param resourceMapping
     * @param uploadType
     * @param isOVDFeatureEnabled
     * @param isSSDMMOTEnabled
     */
    const performBulkUploadForAllOperations = (nodeId, resourceDataMap, narc_sls_intergration_enabled, resourceMapping, uploadType, isOVDFeatureEnabled,
                                               isSSDMMOTEnabled) => {
        const resourcesToAdd = resourceMapping.resourcesToAdd, 
            resourcesToRevive = resourceMapping.resourcesToRevive,
            resourcesToDeprecate = resourceMapping.resourcesToDeprecate;
        const endpoint = getEndpointBasedOnResourceType(narc_sls_intergration_enabled, isSSDMMOTEnabled, resourceDataMap);
        uploadResources(nodeId, resourcesToAdd, capability.data, endpoint, narc_sls_intergration_enabled, isOVDFeatureEnabled)
            .then(result_upload => {
            if (result_upload.uploadBulkResourceSuccess || result_upload.uploadBulkResourceError === ERROR_MAP.NO_RESOURCES_FOUND) {
                // If addition succeded, proceed for deprecation/revival operations
                performBulkRevivalAndDeprecation(resourcesToRevive, resourcesToDeprecate, uploadType);
            } else {
                // If addition failed, return failure response
                dispatch({
                    type: BULK_UPLOAD_RESOURCE, payload: {
                        uploadBulkResourceSuccess: result_upload.uploadBulkResourceSuccess,
                        uploadBulkResourceError: result_upload.uploadBulkResourceError,
                        uploadType: uploadType
                    }
                })
            }
        });
    }

    /**
     * Performs bulk upload for Addition
     * @param nodeId
     * @param resourceDataMap
     * @param narc_sls_intergration_enabled
     * @param isSSDMMOTEnabled
     * @param uploadType
     * @param isNARCSSDBinCreationEnabled
     */
    const performBulkUploadForAdditionOperation = (nodeId, resourceDataMap, narc_sls_intergration_enabled, isSSDMMOTEnabled, uploadType, isNARCSSDBinCreationEnabled) => {
        const endpoint = getEndpointBasedOnResourceType(narc_sls_intergration_enabled, isSSDMMOTEnabled, resourceDataMap);
        uploadResources(nodeId, resourceDataMap, capability.data, endpoint, narc_sls_intergration_enabled, null, isNARCSSDBinCreationEnabled).then(result => {
            dispatch({
                type: BULK_UPLOAD_RESOURCE, payload: {
                    uploadBulkResourceSuccess: result.uploadBulkResourceSuccess,
                    uploadBulkResourceError: result.uploadBulkResourceError,
                    uploadType: uploadType
                }})
        })
    }

    const uploadBulkResources = (nodeId, resourceDataMap) => {
        dispatch( {type: BEGIN_BULK_EXCEL_PARSING, payload : {loaderType : LOADER_TYPE.FILE_UPLOAD}});
        const uploadType = getuploadTypeBasedOnResourceType(narc_sls_intergration_enabled, isSSDMMOTEnabled, resourceDataMap);
        if (narc_sls_intergration_enabled && uploadType === TYPE.RESOURCE) {
            //Step 1 : Generate the mapping of resources which are supposed to be added/deprecated/revived.
            const resourceMapping = fetchMappingOfResourcesAccordingToOperation(nodeId, resourceDataMap, isOVDFeatureEnabled);
            //Step 2 : Perform the corresponding actions : Create(Or Edit)/Deprecate/Revive
            performBulkUploadForAllOperations(nodeId, resourceDataMap, narc_sls_intergration_enabled, resourceMapping, uploadType, isOVDFeatureEnabled,
                isSSDMMOTEnabled);
        } else {
            performBulkUploadForAdditionOperation(nodeId, resourceDataMap, narc_sls_intergration_enabled, isSSDMMOTEnabled, uploadType, isNARCSSDBinCreationEnabled);
        }
    }

    const {bulkResourceCreationModalState, uploadBulkResourceError, uploadBulkResourceSuccess, resourceDataMap, resourceNames, activeTab,
        resourceSearchCriteria, resourceTypes} = state;

    /**
     * Transforms the excel file to display those headers in preview as well which have missing data for some rows
     * @param {*} originalSheetMapList
     * @returns transformedList
     */
    const transformSheetMapList = (originalSheetMapList) => {
        if (!narc_sls_intergration_enabled) {
            return originalSheetMapList;
        }
        let transformedList = [];
        //Finds the list of headers which are present in atleast 1 row in excel sheet
        let allHeaders = new Set();
        originalSheetMapList.forEach(rowMap => {
            Object.keys(rowMap).forEach(header => allHeaders.add(header));
        });
        //Generate the transformed list, by displaying "-" value when a row is missing column value
        originalSheetMapList.forEach(rowMap => {
            let transformedRow = {};
            allHeaders.forEach(header => {
                if(Object.keys(rowMap).includes(header)) {
                    transformedRow[header] = rowMap[header];
                } else {
                    transformedRow[header] = "-";
                }
            })
            transformedList.push(transformedRow);
        })
        return transformedList;
    }
  
    const showResourceData = (resourceDataMap, resourceName) => {
        const headerRows = chainWalk(() => resourceDataMap[resourceName].headerRows, []);
        const sheetMapList = chainWalk(() => transformSheetMapList(resourceDataMap[resourceName].sheetMapList), []);
        const firstVisibleRowInPage = (currentPageIndexInBulkUploadPreview - 1) * ITEMS_PER_PAGE_IN_PREVIEW;
        const lastVisibleRowInPage = firstVisibleRowInPage + ITEMS_PER_PAGE_IN_PREVIEW;
        return headerRows.length > 0 &&
            <Column id="resources_preview">
                <Box type="outline" spacingInset="small" height={400} className="OverflowYAuto">
                    <Table spacing="small" alignment="left" headerRows={1} showStripes={true}>
                        <TableRow>
                            {headerRows.map(headerName =>
                                <TableCell>
                                    <Text alignment="center" type="h100"><FormattedMessage id={headerName} defaultMessage={headerName}/></Text>
                                </TableCell>
                            )}
                        </TableRow>
                        {sheetMapList && sheetMapList.length > 0 && 
                            sheetMapList.slice(firstVisibleRowInPage, lastVisibleRowInPage).map(sheetMap =>
                                <TableRow>
                                    {
                                        headerRows.map(headerName =>
                                        <TableCell>
                                            <Text alignment="center" type="h100">
                                                <FormattedMessage id={sheetMap[headerName]} defaultMessage={sheetMap[headerName]}/>
                                            </Text>
                                        </TableCell>
                                    )}
                                </TableRow>
                        )}
                    </Table>
                    <Pagination
                        showSkipArrows={true}
                        numberOfPages={numPagesInBulkUploadPreview}
                        onChange={targetPageIndex => setCurrentPageIndexInBulkUploadPreview(targetPageIndex)}
                        currentPage={currentPageIndexInBulkUploadPreview}
                    />
                </Box>
            </Column>
    }

    const downloadVinyaasData = () => {
        const resourceSearchCriteria = state.resourceSearchCriteria;
        const nodeId = resourceSearchCriteria["nodeId"];
        switch (capability.data) {
            case SSD:
                dispatch({type: BEGIN_CALL})
                getSSPLaneDataForSSDStation(nodeId);
                dispatch({type: END_CALL_FOR_DOWNLOAD_RESOURCES})
                break;
            // This is to be used for future implementation of other capabilities
            default:
                break;
        }
    }

    const downloadRAData = () => {
        const nodeId = state.resourceSearchCriteria["nodeId"];
        switch (capability.data) {
            case SSD:
                dispatch({type: BEGIN_CALL})
                getRADataForSSDStation(nodeId);
                dispatch({type: END_CALL_FOR_DOWNLOAD_RESOURCES})
                break;
            // This is to be used for future implementation of other capabilities
            default:
                break;
        }
    }

    const renderRADataCreation = () => {
        return ( checkValidCapabilitiesForResourceCreationV2() &&
        <Column>
            <Divider />
            <Row alignment="center justify">
                <Text>
                    {isSSDMMOTEnabled ?
                        <FormattedMessage id="node_resources.download_ra_data_ssd_mmot" defaultMessage="STEP 7: Download Sortation Rules"/>
                        :<FormattedMessage id="node_resources.download_ra_data" defaultMessage="STEP 5: Download Sortation Rules"/>}
                </Text>
                <Button type="primary" size="small" aignment="right" onClick={() => { downloadRAData(); }}>
                    <FormattedMessage id="node_resources.stem_resources" defaultMessage="Get Data"/>
                </Button>
            </Row>
            <Divider />
            <Row alignment="center justify">
                <Text>
                    {isSSDMMOTEnabled ?
                        <FormattedMessage id="node_resources.redirect_stem_ssd_mmot" defaultMessage="STEP 8: Upload Data in STEM"/>
                        :<FormattedMessage id="node_resources.redirect_stem" defaultMessage="STEP 6: Upload Data in STEM"/>}
                </Text>
                <Button type="primary" size="small" aignment="right" onClick={() => {
                    window.open(STEM[getRegion()][getStage()].replace("stationCode", getNodeId()));
                }}>
                    <FormattedMessage id="node_resources.redirect_stem_button" defaultMessage="Redirect"/>
                </Button>
            </Row>
        </Column>)
    }

    const getFilePathForDownloadTemplateFile = () => {
        return checkValidCapabilitiesForResourceCreationV2() ?
            (isNARCSSDBinCreationEnabled ? SSD_V2 : SSD) : DEFAULT;
    }

    const createResourcesInResourceManagement = () => {
        return (
            <Column>
            <Row alignment="center justify">
                <Text>
                    <FormattedMessage id="node_resources.download_template_file" defaultMessage="STEP 1 : Download the template file."/>
                </Text>
                <Button type="primary" size="small" onClick={() => {
                    const filepath = getFilePathForDownloadTemplateFile();
                    window.open(FILE_PATH[filepath]);
                }}>
                    <FormattedMessage id="node_resources.download_template" defaultMessage="Get Template"/>
                </Button>
            </Row>
            <Divider />
            <Row alignment="center justify">
                <Text>
                    <FormattedMessage id="node_resources.upload_file" defaultMessage="STEP 2 : Upload the file containing the resources."/>
                </Text>
                <input ref={createResourceFileRef} style={{ "display": "none" }} type="file"
                       id="input-bulkfileupload"  data-testid="input-bulkfileupload" onChange={(event) => {
                    displayFileData(resourceSearchCriteria.nodeId, resourceTypes, event);
                }}
                />
                <Button type="primary" size="small" onClick={() => {createResourceFileRef.current.click()}}>
                    <FormattedMessage id="node_resources.upload_resource" defaultMessage="Select File"/>
                </Button>
            </Row>
                {isSSDMMOTEnabled && <Divider/>}
            </Column>
        )
    }

    const renderVinyaasDataCreation = () => {
        return ( checkValidCapabilitiesForResourceCreationV2() &&
            <Column>
                <Divider />
                <Row alignment="center justify">
                <Text>
                    {isSSDMMOTEnabled ?
                    <FormattedMessage id="node_resources.download_vinyaas_data_ssd_mmot" defaultMessage="STEP 5: Download Vinyaas Data"/>
                    : <FormattedMessage id="node_resources.download_vinyaas_data" defaultMessage="STEP 3: Download Vinyaas Data"/>}
                </Text>
                <Button type="primary" size="small" aignment="right" onClick={() => { downloadVinyaasData(); }}>
                    <FormattedMessage id="node_resources.vinyaas_resources" defaultMessage="Get Data"/>
                </Button>
                </Row>
                <Divider />
                <Row alignment="center justify">
                    <Text> {isSSDMMOTEnabled ?
                        <FormattedMessage id="node_resources.redirect_vinyaas_ssd_mmot" defaultMessage="STEP 6: Upload Data in vinyaas"/>
                        : <FormattedMessage id="node_resources.redirect_vinyaas" defaultMessage="STEP 4: Upload Data in vinyaas"/>}
                    </Text>
                    <Button type="primary" size="small" aignment="right" onClick={() => {
                        window.open(VINYAAS[getRegion()][getStage()]);
                    }}>
                        <FormattedMessage id="node_resources.redirect_vinyaas_button" defaultMessage="Redirect"/>
                    </Button>
                </Row>
            </Column>)
    }

    /*
       Method to render resources creation
    */
    const createResources = () => {
        return (
            <Column>

                <Row alignment="center justify">
                    <Column>
                        <Row>
                            <Text>
                                <FormattedMessage id="node_resources.download_template_file_SLS_resources" defaultMessage={DEFAULT_FORMATTED_MESSAGES.download_template_file_SLS_resources}/>
                            </Text>
                        </Row>
                        {
                            isOVDFeatureEnabled
                            ?   <>
                                    <Row alignment="center justify">
                                        <Text type="b100">
                                            <FormattedMessage id="node_resources.stem_resources_operations_in_narc_editable" defaultMessage={DEFAULT_FORMATTED_MESSAGES.stem_resources_operations_in_narc_editable}/>
                                        </Text>
                                    </Row>
                                    <Row alignment="center justify">
                                        <Text type="b100">
                                            <FormattedMessage id="node_resources.stem_resources_operations_in_narc_non_editable" defaultMessage={DEFAULT_FORMATTED_MESSAGES.stem_resources_operations_in_narc_non_editable}/>
                                        </Text>
                                    </Row>
                                </>
                            :   <Row alignment='center justify'>
                                    <Text type="b100">
                                        <FormattedMessage id="node_resources.stem_resources_operations_in_narc" defaultMessage={DEFAULT_FORMATTED_MESSAGES.stem_resources_operations_in_narc}/>
                                    </Text>
                                </Row>
                     }
                        <Row alignment='center justify' >
                            <Text type="b100" breakWord="true">
                                <FormattedMessage id="node_resources.sls_resources_operations" defaultMessage={DEFAULT_FORMATTED_MESSAGES.sls_resources_operations}/>
                            </Text>
                        </Row>
                    </Column>
                    <Button type="primary" size="small" onClick={() => {
                        const filePath = isOVDFeatureEnabled ? NodeResourcesOVDFeatureEnabled_excel : NodeResources_excel;
                        window.open(FILE_PATH[filePath]);
                    }}>
                        <FormattedMessage id="node_resources.download_template" defaultMessage={DEFAULT_FORMATTED_MESSAGES.download_template}/>
                    </Button>
                </Row>

                <Divider />

                <Row alignment="center justify">
                    <Column>
                        <Row>
                            <Text>
                                <FormattedMessage id="node_resources.upload_file_SLS_resources" defaultMessage={DEFAULT_FORMATTED_MESSAGES.upload_file_SLS_resources}/>
                            </Text>
                        </Row>
                    </Column>
                    <input ref={createResourceFileRef} style={{ "display": "none" }} type="file"
                        id="input-bulkfileuploadSLS"  data-testid="input-bulkfileuploadSLS" onChange={(event) => {
                        displayFileData(resourceSearchCriteria.nodeId, resourceTypes, event, EXCEL_TYPES.RESOURCES_EXCEL);
                    }}
                    />
                    <Button type="primary" size="small" onClick={() => {createResourceFileRef.current.click()}}>
                        <FormattedMessage id="node_resources.upload_resource" defaultMessage={DEFAULT_FORMATTED_MESSAGES.upload_resource}/>
                    </Button>
                </Row>

                <Divider />

            </Column>
        )
    }

    /*
       Method to render resource associations creation
    */
    const createResourcesAssociation = () => {
        return (
            <Column>
                <Row alignment="center justify">
                    <Column>
                        <Row>
                            <Text>
                                <FormattedMessage id="node_resources.download_template_file_SLS_resources_associations" defaultMessage={DEFAULT_FORMATTED_MESSAGES.download_template_file_SLS_resources_associations}/>
                            </Text>
                        </Row>
                    </Column>
                <Button type="primary" size="small" onClick={() => {
                    isSSDMMOTEnabled ? window.open(FILE_PATH[SSD_NodeResourcesAssociation_excel]):window.open(FILE_PATH[NodeResourcesAssociation_excel])
                }}>
                    <FormattedMessage id="node_resources.download_template" defaultMessage="Get Template"/>
                </Button>
                </Row>
                <Divider />
                <Row alignment="center justify">
                    <Text>
                        <FormattedMessage id="node_resources.upload_file_SLS_resources_associations" defaultMessage={DEFAULT_FORMATTED_MESSAGES.upload_file_SLS_resources_associations}/>
                    </Text>
                    <input ref={createResourcesAssociationFileRef} style={{ "display": "none" }} type="file"
                        id="input-bulkfileupload_associationSLS"  data-testid="input-bulkfileupload_associationSLS" onChange={(event) => {
                        displayFileData(resourceSearchCriteria.nodeId, resourceTypes, event, EXCEL_TYPES.RESOURCES_ASSOCIATIONS_EXCEL);
                    }}
                    />
                    <Button type="primary" size="small" onClick={() => {createResourcesAssociationFileRef.current.click()}}>
                        <FormattedMessage id="node_resources.upload_resource" defaultMessage={DEFAULT_FORMATTED_MESSAGES.upload_resource}/>
                    </Button>
                </Row>
                {!isSSDMMOTEnabled && <Divider />}
            </Column>
        )
    }

    /*
       Method to render redirection for stem
    */
    const render_stem_data = () => {
        return ( narc_sls_intergration_enabled &&
            <Row alignment="center justify">
                <Column>
                    <Row>
                        <Text>
                            <FormattedMessage id="node_resources.redirect_stem_SLS" defaultMessage={DEFAULT_FORMATTED_MESSAGES.redirect_stem_SLS}/>
                        </Text>
                    </Row>
                    <Row>
                        <Text type="b100">
                            <FormattedMessage id="node_resources.stem_service_resources" defaultMessage={DEFAULT_FORMATTED_MESSAGES.stem_service_resources}/>
                        </Text>
                    </Row>
                </Column>
                <Button type="primary" size="small" aignment="right" onClick={() => {
                    window.open(STEM[getRegion()][getStage()].replace("stationCode", getNodeId()));
                }}>
                    <FormattedMessage id="node_resources.redirect_stem_button" defaultMessage={DEFAULT_FORMATTED_MESSAGES.redirect_stem_button}/>
                </Button>
            </Row>
        )
    }

    const checkValidCapabilitiesForResourceCreationV2 = () => {
        return capability.data === SSD;
    }

    let createResourceFileRef = React.createRef();
    let createResourcesAssociationFileRef = React.createRef();

    const createResourcesButton = () => {
        return (
            <Column>
            {!narc_sls_intergration_enabled && createResourcesInResourceManagement()}
            {narc_sls_intergration_enabled && createResources()}
            {(narc_sls_intergration_enabled || isSSDMMOTEnabled) && createResourcesAssociation()}
            {render_stem_data()}
            {renderVinyaasDataCreation()}
            {renderRADataCreation()}
            </Column>
        )
    }

    const confirmUploadResource = () => {
        return (
            <ModalFooter>
                <div style={{"paddingRight":"10px", "paddingBottom":"10px"}}>
                    <Row alignment="center right" widths="fit">
                        <Button type="primary" size="small" onClick={() => {dispatch({type: CLEAR_RESOURCE_DATAMAP})}}>
                            <FormattedMessage id="node_resources.upload_excel_again" defaultMessage="Upload Excel Again"/>
                        </Button>
                        <Button type="primary" size="small" onClick={() => {
                            uploadBulkResources(resourceSearchCriteria.nodeId, resourceDataMap);
                        }}>
                            <FormattedMessage id="node_resources.confirm" defaultMessage={DEFAULT_FORMATTED_MESSAGES.confirm}/>
                        </Button>
                    </Row>
                </div>
            </ModalFooter>
        )
    }

    const showResourceDataMastHead = () => {
        return (
            <Masthead size="large">
                {resourceNames && resourceNames.map(resourceName =>
                    <MastheadLink key={resourceName} selected={activeTab === resourceName}
                                  onClick={() => {
                                    updateActiveTab(resourceName);
                                    }} disabled={false}>
                        <Text type="b100" id="resource_name"> {resourceName} </Text>
                    </MastheadLink>
                )}
            </Masthead>
        )
    }

    const errorAlert = () => {
        return (<Fragment>
                <Alert type="error" size="large">
                    <FormattedMessage id="bulk_resource_failure" defaultMessage={uploadBulkResourceError}/>
                </Alert>
                <br/>
            </Fragment>)
    }

    const successAlert = (narc_sls_intergration_enabled) => {
        return (<Fragment>
                <Alert type="success" size="large">
                    {
                        uploadType === TYPE.RESOURCE
                        ? (narc_sls_intergration_enabled
                            ? <FormattedMessage id="bulk_sls_resource_success" defaultMessage={DEFAULT_FORMATTED_MESSAGES.bulk_sls_resource_success}/>
                            : <FormattedMessage id="bulk_resource_success" defaultMessage={DEFAULT_FORMATTED_MESSAGES.bulk_resource_success}/>)
                        : <FormattedMessage id="bulk_resource_association_success" defaultMessage={DEFAULT_FORMATTED_MESSAGES.bulk_resource_association_success}/>
                    }
                </Alert>
                <br/>
            </Fragment>)
    }

    /**
     * Renders message while generating preview/uploading file
     * @returns 
     */
    const getLoaderMessage = () => {
        return loaderType === LOADER_TYPE.PREVIEW ?
        <FormattedMessage id="node_resources.preview_loader" defaultMessage={DEFAULT_FORMATTED_MESSAGES.preview_loader}/>
        :  <FormattedMessage id="node_resources.file_upload_loader" defaultMessage={DEFAULT_FORMATTED_MESSAGES.file_upload_loader}/>
    }

    /**
     * Renders Modal Preview Header
     * @returns 
     */
    const renderModalPreviewHeader = () => {
        return narc_sls_intergration_enabled ?
        <FormattedMessage id="node_resources.sls_resource_confirmation_view" defaultMessage={DEFAULT_FORMATTED_MESSAGES.sls_resource_confirmation_view}/>
        : <FormattedMessage id="node_resources.resource_confirmation_view" defaultMessage={DEFAULT_FORMATTED_MESSAGES.resource_confirmation_view}/>
    }

    return (
        <Modal title={renderHeaderMessage(narc_sls_intergration_enabled)} id="modal-bulkResourceCreation" scrollContainer="viewPort" describedById="resource_creation_preview"
               open={bulkResourceCreationModalState} onOpen={() => { }} onClose={() => { concludeBulkResourceCreation(resourceSearchCriteria, false); }}
        >
            {loadingFile && <TableRow><TableCell columnSpan={7} alignmentHorizontal="center"><Loader/>{getLoaderMessage()}</TableCell></TableRow>}
            {!loadingFile && uploadBulkResourceError && errorAlert()}
            {!loadingFile && uploadBulkResourceSuccess && successAlert(narc_sls_intergration_enabled)}
            {!loadingFile && !uploadBulkResourceSuccess && Object.keys(resourceDataMap).length === 0 && createResourcesButton()}
            {!loadingFile && Object.keys(resourceDataMap).length !== 0 &&
                <Column id="resource_creation_preview">
                    <Heading level={3} alignment="center">
                        <div style={{"paddingTop":"10px"}}>
                            {renderModalPreviewHeader()}
                        </div>
                    </Heading>
                    {isOVDFeatureEnabled &&  <FormattedMessage id="node_resources.sls_resource_confirmation_view_notes" defaultMessage={DEFAULT_FORMATTED_MESSAGES.sls_resource_confirmation_view_notes}/>}
                    {showResourceDataMastHead()}
                    {showResourceData(resourceDataMap, activeTab)}
                    {confirmUploadResource()}
                </Column>
            }
        </Modal>
    );
}
