import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import { HashRouter, Switch } from 'react-router-dom';
import { NARCRoute } from './components/util/NARCRoute';
import StationLayout from './components/StationLayout.js';
import {publishCount} from "./MetricUtility";
import { withRouter } from 'react-router-dom';
import BinTypes from "./components/BinTypes";
import NodeResourceManager from "./components/node-resources/NodeResourceManager";
import RBLConfig from "./components/rbl-config/RBLConfig";
import StationManagementManager from "./components/station-management/StationManagementManager";
import StationViewManagementManager from "./components/station-management/StationViewManagementManager";
import EmployeeManagementManager from "./components/employee-management/EmployeeManagementManager";
import EmployeeFormManager from "./components/employee-management/EmployeeFormManager";
import RegisterEmployeeManager from "./components/employee-management/RegisterEmployeeManager";
import SortPathTabs from "./components/sort-paths/SortPathTabs";
import {getNodeId, isConfigEnabled} from "./Utility";
import {
    CAPABILITY,
    FEATURES, IS_SSD_GROCERY_SORT_PATH_ENABLED,
    STATIC_GRID_ALLOCATION_ENABLED
} from "./Constants";
import UploadedSortPaths from "./components/sort-paths/UploadedSortPaths";
import {getStationConfigs} from "./resources/SortPathSettingsResource";
import Loading from "./components/util/Loading";
import {StationConfigContext} from "./context/StationConfigContext";
import {UPDATE_CONFIGS} from "./reducers/StationConfigReducer";
import {
    getFeaturesFromParent,
    getNodeCapabilityFromParent
} from './handler/ParentDataHandler';
import {chainWalk} from "./components/util/CommonUtils";
import {FeatureConfigUtils} from "./components/util/FeatureConfigUtils";

export const App = (props) => {

    const [sortPathUI, setSortPathUI] = useState("LOADING");
    const {dispatch} = useContext(StationConfigContext);

    useEffect(() => {
        publishRoutePath(props.location);
        const onRouteSwitch = props.history.listen(() => {
            publishRoutePath(props.location);
        });
        return onRouteSwitch;
    }, []);

    useEffect(() => {
        let sortPathUI = "STATIC_GRID_ALLOCATION_DISABLED";
        getStationConfigs(getNodeId()).then(async data => {
            if (data && data.stationConfigs) {
                dispatch({type: UPDATE_CONFIGS, data: data})
                const capability = await getNodeCapabilityFromParent();
                const capabilityParsed = JSON.parse(capability.capability);
                FeatureConfigUtils.setIsSSD3EnabledStation(await getFeaturesFromParent([FEATURES.IS_SSD3_ENABLED])
                    .then(featureStatus => {return chainWalk(() => featureStatus.data[FEATURES.IS_SSD3_ENABLED],
                        false)
                }));
                FeatureConfigUtils.setIsSSDGrocerySortPathEnabledStation(isConfigEnabled(IS_SSD_GROCERY_SORT_PATH_ENABLED, data.stationConfigs))
                const isStaticGridConfigEnabled = (isConfigEnabled(STATIC_GRID_ALLOCATION_ENABLED, data.stationConfigs)
                            && capabilityParsed===CAPABILITY.AMZL_CORE) || FeatureConfigUtils.getIsSSD3EnabledStation() ||
                                                FeatureConfigUtils.getIsSSDGrocerySortPathEnabledStation();
                if (isStaticGridConfigEnabled) {
                    sortPathUI = "STATIC_GRID_ALLOCATION_ENABLED";
                }
            }
        }, (_error) => {
        }).finally(() => setSortPathUI(sortPathUI));
    }, []);

    return (
        <HashRouter>
            <Switch>
                <NARCRoute path="/station" component={StationManagementManager} />
                <NARCRoute path="/stationView" component={StationViewManagementManager} />
                <NARCRoute path="/employee" component={EmployeeManagementManager} />
                <NARCRoute path='/employeeForm' component={EmployeeFormManager}/>
                <NARCRoute path="/employeeRegister" component={RegisterEmployeeManager} />
                <NARCRoute path="/station-layout" component={StationLayout} />
                <NARCRoute path="/bin-types" component={BinTypes} />
                <NARCRoute path="/node-resources" component={NodeResourceManager} />
                { sortPathUI === "LOADING" ?
                    <NARCRoute path="/sort-paths" component={Loading}/> :
                    sortPathUI === "STATIC_GRID_ALLOCATION_ENABLED" ?
                        <NARCRoute path="/sort-paths" component={SortPathTabs} /> :
                        <NARCRoute path="/sort-paths" component={UploadedSortPaths} />
                }
                <NARCRoute path="/rbl-config" component={RBLConfig} />
            </Switch>
        </HashRouter>
    );
}

function publishRoutePath(location) {
    const metricProcessName = "SCC_NARC";
    const metricName = location.pathname;
    publishCount(metricProcessName, metricName);
}

export default withRouter(App);
