import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {
    MULTIPLE_CYCLE_PER_PARTITION_ENABLED,
    RESOURCE_TYPES,
    SORT_GRID_OVERLAP_DETAILS_ENABLED,
    SortGridAllocationCommonFormStates,
    SortGridAllocationFillFormStates,
    SortGridAllocationFormStates,
    SortGridAllocationPickFormStates,
    CYCLE,
    STRINGS
} from "../../Constants";
import Button from "@amzn/meridian/button";
import Divider from "@amzn/meridian/divider";
import Text from "@amzn/meridian/text";
import Input from "@amzn/meridian/input";
import get from "lodash/get";
import {buildSortPathConfigurationDataObject,} from "../util/sort-paths/SortPathConfigurationData";
import {createSortPathConfiguration,} from "../../resources/SortPathSettingsResource";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import SelectOption from "@amzn/meridian/select/select-option";
import Select from "@amzn/meridian/select";
import SortGridAllocationModal from "./SortGridAllocationModal";
import SortGridAllocationPickForm from "./SortGridAllocationPickForm";
import {addToObject} from "../util/CommonUtils";
import SortGridAllocationPartitionInputForm from "./SortGridAllocationPartitionInputForm";
import SortGridAllocationFillForm from "./SortGridAllocationFillForm";
import {getNodeId, isConfigEnabled} from "../../Utility";
import {getInitialFormDataState} from "../util/sort-paths/SortPathConfigurationInputs";
import {StationConfigContext} from "../../context/StationConfigContext";
import {
    isDynamicCycle,
    isSideline
} from "../util/sort-paths/CommonUtils";
import {getResourceData} from "../../resources/NodeResourceManagerResource";
import Tooltip from "@amzn/meridian/tooltip";
import {
    validateAdtaClusterIdentifierSettings,
    validateColumnLengthForPartialColumnZebraType,
    validateCustomSettingForResource,
    validateFillFormAlgorithm,
    validateIncludedLocationsForDynamicCycle, validateIncludedSortZonesForSideline,
    validateSidelineSortZonesNomenclature,
    validateMaxClusterProximitySettings,
    validatePickPathPattern,
    validateFillFormDataForSSD3,
    validateCommonFormDataForSSD3,
    validatePickFormDataForSSD3,
    validateCommonFormDataForSSDGrocery,
    validateFillFormDataForSSDGrocery,
    validatePickFormDataForSSDGrocery
} from "../util/sort-paths/SortPathSettingValidationUtils";
import SortGridOverlapDetails from "./SortGridOverlapDetails";
import SortGridAllocationCycleSelectionForm from "./SortGridAllocationCycleSelectionForm";
import SortGridAllocationResourceSequenceForm from "./SortGridAllocationResourceSequenceForm";
import {FeatureConfigUtils} from "../util/FeatureConfigUtils";

/**
 * React component for SortGridAllocationForm.
 */
const SortGridAllocationForm = (props) => {
    const isFillAlgorithmFirstUpdate = useRef(true);
    const isPickAlgorithmFirstUpdate = useRef(true);
    const [isGridAllocationSubmitModalOpen, setIsGridAllocationSubmitModalOpen] = useState(false);
    const [isGridAllocationInProgress, setIsGridAllocationInProgress] = useState(false);
    const [gridAllocationError, setGridAllocationError] = useState(undefined);
    const [clusterResources, setClusterResources] = useState([]);
    const [fetchClusterResources, setFetchClusterResources] = useState(true);
    const [toolTipOpen, setToolTipOpen] = useState(false);
    const toolTipMessage = useRef("");

    const {state} = useContext(StationConfigContext);
    const isSSDGrocerySortPathEnabledStation = FeatureConfigUtils.getIsSSDGrocerySortPathEnabledStation();

    const {
        intl, dataVersion, setDataVersion, isNewGridAllocation, formInputs, formData, setIsNewGridAllocation,
        setIsGridAllocationFormOpen, setFormInputs, setFormData, sortPathConfigurationList
    } = props;


    const resetToDefaultState = useCallback((currentDataVersion) => {
        setIsGridAllocationFormOpen(false);
        setFormInputs({});
        setFormData(getInitialFormDataState());
        setIsNewGridAllocation(undefined);
        setIsGridAllocationSubmitModalOpen(false);
        setDataVersion(currentDataVersion + 1);
    }, []);

    const onClickSubmitGridAllocationButton = useCallback((formData) => {
        const sortPathConfigurationData = buildSortPathConfigurationDataObject(formData);
        setIsGridAllocationInProgress(true);
        setIsGridAllocationSubmitModalOpen(true);

        const response = createSortPathConfiguration(getNodeId(), sortPathConfigurationData);
        response.then(data => {
            if (!data) {
                setGridAllocationError(STRINGS.RETRY_LATER);
            } else {
                const {sortPathSetting, errorMessage} = data;

                if (sortPathSetting) {
                    setGridAllocationError(undefined);
                } else {
                    try {
                        const parsedMessage = JSON.parse(errorMessage || `{"message": "${STRINGS.RETRY_LATER}"`);
                        setGridAllocationError(parsedMessage.message);
                    } catch (_error) {
                        setGridAllocationError(STRINGS.RETRY_LATER);
                    }
                }
            }
        }, error => {
            setGridAllocationError(error);
        }).finally(() => setIsGridAllocationInProgress(false));
    }, []);


    const addToFormData = (key, value) => {
        setFormData(addToObject(formData, key, value));
    };

    const addToCommonFormData = (key, value) => {
        const commonFormData = addToObject(formData.commonFormData, key, value);
        addToFormData(SortGridAllocationFormStates.COMMON_FORM_DATA, commonFormData);
    };

    const addToFillFormData = (key, value) => {
        const fillFormData = addToObject(formData.fillFormData, key, value);
        addToFormData(SortGridAllocationFormStates.FILL_FORM_DATA, fillFormData);
    };

    const addToPickFormData = (key, value) => {
        const pickFormData = addToObject(formData.pickFormData, key, value);
        addToFormData(SortGridAllocationFormStates.PICK_FORM_DATA, pickFormData);
    };

    const addToCommonFormInputs = (key, value) => {
        const updatedCommonFormInputs = addToObject(formInputs.commonFormInputs, key, value);
        setFormInputs(addToObject(formInputs, "commonFormInputs", updatedCommonFormInputs));
    };

    const setResourceSequences = (value) => {
        const pickFormData = addToObject(formData.pickFormData, SortGridAllocationPickFormStates.RESOURCE_SEQUENCES, value)
        const fillFormData = addToObject(formData.fillFormData, SortGridAllocationFillFormStates.RESOURCE_SEQUENCES, value)
        setFormData({...formData, pickFormData: pickFormData, fillFormData: fillFormData})
    }

    const validateCommonFormData = (commonFormData) => {
        if (!(commonFormData.cycle && commonFormData.name && commonFormData.name.trim().length > 0 &&
            (!isConfigEnabled(MULTIPLE_CYCLE_PER_PARTITION_ENABLED, state.stationConfigs) ||
                (commonFormData.cycleList && commonFormData.cycleList.length > 0)))) {
            toolTipMessage.current = intl.formatMessage({id: "sortPaths.missingCycleIdOrNameToolTipMessage"});
            return false;
        }
        // Validate included location information only if cycle is dynamic
        if (!(!isDynamicCycle(commonFormData.cycle, commonFormData.nodeId, state.cycleList) || validateIncludedLocationsForDynamicCycle(commonFormData))) {
            toolTipMessage.current = intl.formatMessage({id: "sortPaths.missingIncludedLocationsTipMessage"});
            return false;
        }
        return true;
    };

    const validateFillFormData = (fillFormData) => {
        if (!(fillFormData.algorithm && validateCustomSettingForResource(fillFormData.resourceSequences) &&
            validateFillFormAlgorithm(fillFormData))) {
            toolTipMessage.current = intl.formatMessage({id: "sortPaths.missingFillFormDataTipMessage"});
             return false;
        }
        if (!isDynamicCycle(get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA, SortGridAllocationCommonFormStates.CYCLE]),
            get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA, SortGridAllocationCommonFormStates.NODE_ID]), state.cycleList)) {
            if (!validateMaxClusterProximitySettings(fillFormData, clusterResources)) {
                toolTipMessage.current = intl.formatMessage({id: "sortPaths.missingMaxClusterCapacityForClustersTipMessage"});
                return false;
            }
            if (!validateAdtaClusterIdentifierSettings(fillFormData, clusterResources)) {
                toolTipMessage.current = intl.formatMessage({id: "sortPaths.missingAdtaClusterIdentifierForClustersTipMessage"});
                return false;
            }
        }
        return true;
    };

    const validatePickFormData = (pickFormData) => {
        if (!(validatePickPathPattern(pickFormData) && validateCustomSettingForResource(pickFormData.resourceSequences))) {
            toolTipMessage.current = intl.formatMessage({id: "sortPaths.missingPickFormDataTipMessage"});
            return false;
        }
        if (!(validateColumnLengthForPartialColumnZebraType(pickFormData))) {
            toolTipMessage.current = intl.formatMessage({id: "sortPaths.missingColumnLengthInPickFormDataTipMessage"});
            return false;
        }
        return true;
    };

    const validateSSD3FormData = (formData) => {
        if (!(validateCommonFormDataForSSD3(formData.commonFormData))) {
            toolTipMessage.current = intl.formatMessage({id: "sortPaths.incorrectIncludedSortLocationsTipMessage"});
            return false;
        }
        //Both Fill Form and Pick Form should always have a sequence selected.
        if (!validateFillFormDataForSSD3(formData.fillFormData) || !validatePickFormDataForSSD3(formData.pickFormData)) {
            toolTipMessage.current = intl.formatMessage({id: "sortPaths.incorrectSequenceSelectedTipMessage"});
            return false;
        }
        toolTipMessage.current = intl.formatMessage({id: "sortPaths.toolTipSuccessMessage"});
        return true;
    }

    const areRequiredFieldsPresent = (formData) => {
        if (FeatureConfigUtils.getIsSSDGrocerySortPathEnabledStation()) {
            if (formData.commonFormData.isGrocerySortPath === true) {
                if (!validateCommonFormDataForSSDGrocery(formData.commonFormData)) {
                    toolTipMessage.current = intl.formatMessage({id: "sortPaths.incorrectIncludedSortLocationsTemperatureTipMessage"});
                    return false;
                }
                //Both Fill Form and Pick Form should always have a sequence selected.
                if (!validateFillFormDataForSSDGrocery(formData.fillFormData) || !validatePickFormDataForSSDGrocery(formData.pickFormData)) {
                    toolTipMessage.current = intl.formatMessage({id: "sortPaths.incorrectSequenceSelectedTipMessage"});
                    return false;
                }
                toolTipMessage.current = intl.formatMessage({id: "sortPaths.toolTipSuccessMessage"});
                return true;
            } else {
                if (FeatureConfigUtils.getIsSSD3EnabledStation()) {
                    return validateSSD3FormData(formData);
                } else {
                    toolTipMessage.current = intl.formatMessage({id: "sortPaths.onlyGroceryBinSortPathSupported"});
                    return false;
                }
            }
        } else if (FeatureConfigUtils.getIsSSD3EnabledStation()) {
            return validateSSD3FormData(formData);
        } else if (isDynamicCycle(formData.commonFormData.cycle, formData.commonFormData.nodeId, state.cycleList)) {
            if (!!(validateCommonFormData(formData.commonFormData) &&
                validateFillFormData(formData.fillFormData))) {
                toolTipMessage.current = intl.formatMessage({id: "sortPaths.toolTipSuccessMessage"});
                return true;
            }
            return false;
        } else if (isSideline(formData.commonFormData.cycle, formData.commonFormData.nodeId, state.cycleList)){
            if (!(validateIncludedSortZonesForSideline(formData.commonFormData))) {
                toolTipMessage.current = intl.formatMessage({id: "sortPaths.missingIncludedSortZonesTipMessage"});
                return false;
            }
            if (!validateSidelineSortZonesNomenclature(formData.commonFormData)) {
                toolTipMessage.current = intl.formatMessage({id: "sortPaths.incorrectIncludedSortZonesTipMessage"});
                return false;
            }
            toolTipMessage.current = intl.formatMessage({id: "sortPaths.toolTipSuccessMessage"});
            return true;
        } else {
            if (!!(validateCommonFormData(formData.commonFormData) &&
                validateFillFormData(formData.fillFormData) &&
                validatePickFormData(formData.pickFormData))) {
                toolTipMessage.current = intl.formatMessage({id: "sortPaths.toolTipSuccessMessage"});
                return true;
            }
            return false;
        }
    };

    useEffect(() => {
        if (isFillAlgorithmFirstUpdate.current) {
            isFillAlgorithmFirstUpdate.current = false;
            return;
        }

        if (isDynamicCycle(get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA, SortGridAllocationCommonFormStates.CYCLE]),
            get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA, SortGridAllocationCommonFormStates.NODE_ID]), state.cycleList)) {
            // No reset required in fill form data for dynamic cycles as we don't provide these options
            return;
        }

        let fillFormData = formData.fillFormData;
        fillFormData = addToObject(fillFormData, SortGridAllocationFillFormStates.RESOURCE_SEQUENCES, {});
        fillFormData = addToObject(fillFormData, SortGridAllocationFillFormStates.PARAMETERS, {});
        addToFormData(SortGridAllocationFormStates.FILL_FORM_DATA, fillFormData);
    }, [get(formData, [SortGridAllocationFormStates.FILL_FORM_DATA, SortGridAllocationFillFormStates.ALGORITHM])]);

    useEffect( () => {
        if (fetchClusterResources) {
            getResourceData({nodeId: getNodeId(), resourceType: RESOURCE_TYPES.CLUSTER}).then(result => {
                setClusterResources(result.resources);
            });
            setFetchClusterResources(false);
        }
    }, [clusterResources]);

    useEffect(() => {
        if (isPickAlgorithmFirstUpdate.current) {
            isPickAlgorithmFirstUpdate.current = false;
            return;
        }

        if (isDynamicCycle(get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA, SortGridAllocationCommonFormStates.CYCLE]),
            get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA, SortGridAllocationCommonFormStates.NODE_ID]), state.cycleList)) {
            // No reset required in fill form data for dynamic cycles as we don't provide these options
            return;
        }

        addToPickFormData(SortGridAllocationPickFormStates.RESOURCE_SEQUENCES, {});
    }, [get(formData, [SortGridAllocationFormStates.PICK_FORM_DATA, SortGridAllocationPickFormStates.ALGORITHM])]);


    const renderNameInputForm = (isNewGridAllocation, isSideline) => {
        return (
            <Row>
                <Column spacing={"small"}>
                    <Row>
                        <Text type={"h100"}>
                            <FormattedMessage id="sortPaths.name" defaultMessage="Name" />:
                        </Text>
                    </Row>
                    <Row>
                        <Input value={get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA, SortGridAllocationCommonFormStates.NAME])}
                           onChange={value => addToCommonFormData(SortGridAllocationCommonFormStates.NAME, value)}
                           type="text"
                           disabled={!isNewGridAllocation} />
                    </Row>
                </Column>
                {/*Bin Type*/}
                {!isSideline &&
                    <Column spacing={"small"}>
                        <Row>
                            <Text type={"h100"}>
                                <FormattedMessage id="sortPaths.selectBinType" defaultMessage="Select Bin Type"/>:
                            </Text>
                        </Row>
                        <Row>
                            <div data-testid="selectBinType">
                                <Select
                                    value={get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA, SortGridAllocationCommonFormStates.BIN_TYPE])}
                                    onChange={value => addToCommonFormData(
                                        SortGridAllocationCommonFormStates.BIN_TYPE,
                                        value
                                    )}
                                    name="selectBinType"
                                    disabled={!props.isAuthorized}
                                    minWidth={150}>
                                    {state.binTypes.map(binType => <SelectOption key={binType.type}
                                                                                 value={binType.type}
                                                                                 label={binType.type}/>)}
                                </Select>
                            </div>
                        </Row>
                    </Column>
                }
            </Row>
        );
    }

    /* 1. SSD Stations do not use cycles. Only a single continuous cycle exists and so not showing cycle selection forms
    *  2. single sequence form is needed to fill both Fill form and pick form options.
    * */

    const renderGridAllocationForm = () => {
        const cycle = get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA, SortGridAllocationCommonFormStates.CYCLE]);
        const nodeId = get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA, SortGridAllocationCommonFormStates.NODE_ID]);
        const includedLocations = get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA, SortGridAllocationCommonFormStates.INCLUDED_LOCATIONS]);
        return (
            <Column width={"100%"} spacing={"medium"}>
                <Row>
                    <Column width={"100%"} spacing={"xsmall"}>
                        <Row>
                            <Text type={"h300"}>
                                <FormattedMessage id={ isNewGridAllocation ? "sortPaths.addNewSortGridAllocation" : "sortPaths.editSortGridAllocation" }
                                                  defaultMessage={ isNewGridAllocation ? "Add new sort grid allocation": "Edit sort grid allocation" } />
                            </Text>
                        </Row>
                        <Divider size={"medium"}/>
                    </Column>
                </Row>
                <Row>
                    <Column spacing={"large"}>
                        {!FeatureConfigUtils.getIsSSD3EnabledStation() && !isSSDGrocerySortPathEnabledStation &&
                        <Row>
                             <Column>
                                <SortGridAllocationCycleSelectionForm
                                    commonFormData={get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA])}
                                    commonFormInputs={formInputs.commonFormInputs}
                                    addToCommonFormData={addToCommonFormData}
                                    addToCommonFormInputs={addToCommonFormInputs}
                                    setFormData={setFormData}
                                    setFormInputs={setFormInputs}
                                    isNewGridAllocation={isNewGridAllocation}
                                    intl={intl}
                                    sortPathConfigurationList={sortPathConfigurationList}
                                    isAuthorized={props.isAuthorized}
                                />
                                {cycle === CYCLE.SIDELINE ?
                                    renderNameInputForm(isNewGridAllocation, true) :
                                    renderNameInputForm(isNewGridAllocation, false)
                                }
                                {cycle === CYCLE.SIDELINE &&
                                    <Column spacing={"small"}>
                                        <Row>
                                            <Text type={"h100"}>
                                                <FormattedMessage id="sortPaths.includedSortZones" defaultMessage="Included sort-zones" />:
                                            </Text>
                                        </Row>
                                        <Row>
                                            <Input value={get(includedLocations, ["includedSortZones"])}
                                                   data-testid= {"includedSortZones"}
                                                   disabled={!props.isAuthorized}
                                                   onChange={ value => addToCommonFormData(SortGridAllocationCommonFormStates.INCLUDED_LOCATIONS,
                                                       addToObject(includedLocations, "includedSortZones", value)
                                                   )}
                                                   type="text"/>
                                        </Row>
                                    </Column>
                                }

                            </Column>
                        </Row>
                        }
                        {cycle !== CYCLE.SIDELINE && (
                            <>
                                <Divider size={"small"}/>
                                <SortGridAllocationPartitionInputForm
                                    commonFormInputs={formInputs.commonFormInputs}
                                    commonFormData={get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA])}
                                    addToCommonFormData={addToCommonFormData}
                                    isAuthorized={props.isAuthorized}
                                    isNewGridAllocation={isNewGridAllocation}
                                />
                                {(FeatureConfigUtils.getIsSSD3EnabledStation() || isSSDGrocerySortPathEnabledStation) && (
                                    <>
                                        <Divider size={"small"}/>
                                        <Column spacing={"large"} width={"50%"} alignmentVertical={"top"}>
                                        <SortGridAllocationResourceSequenceForm algorithm={formData.pickFormData.algorithm}
                                                                                resourceSequenceInputs={formInputs.pickFormInputs.resourceSequenceInputs}
                                                                                resourceSequences={formData.pickFormData.resourceSequences}
                                                                                setResourceSequences={setResourceSequences}
                                                                                isAuthorized={props.isAuthorized}
                                        />
                                        </Column>
                                    </>)
                                }
                                {!FeatureConfigUtils.getIsSSD3EnabledStation() && !isSSDGrocerySortPathEnabledStation && isConfigEnabled(SORT_GRID_OVERLAP_DETAILS_ENABLED, state.stationConfigs) && (
                                    <SortGridOverlapDetails
                                        formData={formData}
                                        areRequiredFieldsPresent={areRequiredFieldsPresent}
                                        isAuthorized={props.isAuthorized}
                                    />
                                )}
                                {!FeatureConfigUtils.getIsSSD3EnabledStation() && !isSSDGrocerySortPathEnabledStation &&
                                <>
                                <Divider size={"small"}/>
                                <SortGridAllocationFillForm fillFormInputs={formInputs.fillFormInputs}
                                                            formData={formData}
                                                            clusterResources={clusterResources}
                                                            addToFillFormData={addToFillFormData}
                                                            cycleList={state.cycleList}
                                                            isAuthorized={props.isAuthorized}
                                />
                                </>}
                                {!FeatureConfigUtils.getIsSSD3EnabledStation() && !isSSDGrocerySortPathEnabledStation && !isDynamicCycle(cycle, nodeId, state.cycleList) &&
                                    <>
                                        <Divider size={"small"}/>
                                        <SortGridAllocationPickForm pickFormInputs={formInputs.pickFormInputs}
                                                                    pickFormData={formData.pickFormData}
                                                                    addToPickFormData={addToPickFormData}
                                                                    isAuthorized={props.isAuthorized}
                                        />
                                    </>
                                }
                            </>
                        )}

                    </Column>
                </Row>
                <Divider size={"small"}/>
                <Row alignmentHorizontal="right">
                    <Column>
                        <Button type="secondary" onClick={() => resetToDefaultState(dataVersion)}>
                            <FormattedMessage id="sortPaths.cancel" defaultMessage="Cancel"/>
                        </Button>
                    </Column>

                    { props.isAuthorized && <Column>
                        <Tooltip open={toolTipOpen} position={"top"} title={toolTipMessage.current}>
                            <Button data-testid="submitButton"
                                    onClick={() => onClickSubmitGridAllocationButton(formData)}
                                    disabled={!areRequiredFieldsPresent(formData)}
                                    onMouseEnter={() => {
                                        setToolTipOpen(true);
                                    }}
                                    onMouseLeave={() => setToolTipOpen(false)}>
                                <FormattedMessage id="sortPaths.submit" defaultMessage="Submit"/>
                            </Button>
                        </Tooltip>
                    </Column>
                    }
                </Row>
            </Column>
        );
    }

    return (
        <Row>
            <Column spacingInset={"medium none none none"}>
                <Row>
                    { renderGridAllocationForm() }
                </Row>
            </Column>
            <SortGridAllocationModal isNewGridAllocation={isNewGridAllocation}
                                     isGridAllocationSubmitModalOpen={isGridAllocationSubmitModalOpen}
                                     isGridAllocationInProgress={isGridAllocationInProgress}
                                     gridAllocationError={gridAllocationError}
                                     dataVersion={dataVersion}
                                     resetToDefaultState={resetToDefaultState}
                                     isAuthorized={props.isAuthorized}
            />
        </Row>
    );
};

export default injectIntl(SortGridAllocationForm);
