import { ACTION_TYPE, DEPRECATE_ASSOCIATION_MODAL_ATTRIBUTES, FRONTEND_TO_BACKEND_ATTRIBUTES_MAPPING, TYPE } from "../Constants"
import { reverseMapping } from "../helper/NodeResourceManagerHelper"

export const TOGGLE_UPDATE_RESOURCE_MODAL = "TOGGLE_UPDATE_RESOURCE_MODAL"
export const GET_RESOURCE_DATA = "GET_RESOURCE_DATA"
export const GET_RESOURCE_DATA_SUCCESS = "GET_RESOURCE_DATA_SUCCESS"
export const CLEAR_RESOURCE_DATA = "CLEAR_RESOURCE_DATA"
export const TOGGLE_DEPRECATE_RESOURCE_MODAL = "TOGGLE_DEPRECATE_RESOURCE_MODAL"
export const TOGGLE_DEPRECATE_RESOURCE_ASSOCIATION_MODAL = "TOGGLE_DEPRECATE_RESOURCE_ASSOCIATION_MODAL"
export const CONCLUDE_BULK_RESOURCE_CREATION_MODAL = "CONCLUDE_BULK_RESOURCE_CREATION_MODAL"
export const BULK_UPLOAD_RESOURCE = "BULK_UPLOAD_RESOURCE"
export const CONTRACT_RESOURCE = "CONTRACT_RESOURCE"
export const BEGIN_CALL = "BEGIN_CALL"
export const BEGIN_BULK_EXCEL_PARSING = "BEGIN_BULK_EXCEL_PARSING"
export const END_CALL_FOR_DOWNLOAD_RESOURCES = "END_CALL_FOR_DOWNLOAD_RESOURCES"
export const GET_IMMEDIATE_CHILDREN_DATA_SUCCESS = "GET_IMMEDIATE_CHILDREN_DATA_SUCCESS"
export const ERROR_CALL = "ERROR_CALL"
export const UPDATE_ACTIVE_TAB = "UPDATE_ACTIVE_TAB"
export const UPDATE_BULK_RESOURCE_MODAL_STATE = "UPDATE_BULK_RESOURCE_MODAL_STATE"
export const VALIDATE_AND_DOWNLOAD_RESOURCE_MODAL_STATE = "VALIDATE_AND_DOWNLOAD_RESOURCE_MODAL_STATE"
export const CONCLUDE_VALIDATE_AND_DOWNLOAD_RESOURCE_MODAL_STATE = "CONCLUDE_VALIDATE_AND_DOWNLOAD_RESOURCE_MODAL_STATE"
export const READ_RESOURCE_FILE_SUCCESS = "READ_RESOURCE_FILE_SUCCESS"
export const READ_RESOURCE_FILE_FAILURE = "READ_RESOURCE_FILE_FAILURE"
export const VALIDATE_RESOURCE_FAILURE = "VALIDATE_RESOURCE_FAILURE"
export const GENERATE_VALIDATION_REPORT_START = "GENERATE_VALIDATION_REPORT_START"
export const GENERATE_VALIDATION_REPORT_FAILURE = "GENERATE_VALIDATION_REPORT_FAILURE"
export const GENERATE_VALIDATION_REPORT_SUCCESS = "GENERATE_VALIDATION_REPORT_SUCCESS"
export const VALIDATE_AISLE_TO_STAGING_ZONE_DISTANCE_RESOURCE_SUCCESS = "VALIDATE_AISLE_TO_STAGING_ZONE_DISTANCE_RESOURCE_SUCCESS";
export const DOWNLOAD_AISLE_TO_STAGING_ZONE_DISTANCE_RESOURCE_SUCCESS = "DOWNLOAD_AISLE_TO_STAGING_ZONE_DISTANCE_RESOURCE_SUCCESS";
export const DOWNLOAD_RESOURCES_SUCCESS = "DOWNLOAD_RESOURCES_SUCCESS";
export const UPDATE_RESOURCE_SEARCH_CRITERIA = "UPDATE_RESOURCE_SEARCH_CRITERIA"
export const DEPRECATE_RESOURCE_SUCCESS = "DEPRECATE_RESOURCE_SUCCESS"
export const DEPRECATE_RESOURCE_FAILURE = "DEPRECATE_RESOURCE_FAILURE"
export const CLEAR_RESOURCE_DATAMAP = "CLEAR_RESOURCE_DATAMAP"
export const SET_CURRENT_RESOURCE = "SET_CURRENT_RESOURCE";
export const SET_CURRENT_RESOURCE_ASSOCIATION = "SET_CURRENT_RESOURCE_ASSOCIATION";
export const TOGGLE_VIEW_RESOURCE_MODAL = "TOGGLE_VIEW_RESOURCE_MODAL";
export const UPDATE_RESOURCE_DATA = "UPDATE_RESOURCE_DATA";
export const UPDATE_RESOURCE_FAILURE = "UPDATE_RESOURCE_FAILURE";
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS";
export const UPDATE_COUNTRY_CODE = "UPDATE_COUNTRY_CODE";
export const SET_CURRENT_RESOURCE_TAB = "SET_CURRENT_RESOURCE_TAB";
export const CLEAR_RESOURCE_TYPE = "CLEAR_RESOURCE_TYPE";
export const NARC_SLS_INTEGRATION_ENABLED = "NARC_SLS_INTEGRATION_ENABLED";
export const UPDATE_RESOURCE_RELATIONSHIP_SUCCESS = "UPDATE_RESOURCE_RELATIONSHIP_SUCCESS";
export const UPDATE_RESOURCE_RELATIONSHIP_FAILURE = "UPDATE_RESOURCE_RELATIONSHIP_FAILURE";
export const SET_IS_VALID_EDIT_MODAL = "SET_IS_VALID_EDIT_MODAL";
export const BEGIN_CALL_FOR_VALIDATE_AND_DOWNLOAD_RESOURCE = "BEGIN_CALL_FOR_VALIDATE_AND_DOWNLOAD_RESOURCE";
export const SET_IS_AUTHORIZED_USER = "SET_IS_AUTHORIZED_USER";
export const SET_OVD_FEATURE_ENABLED_FLAG = "SET_OVD_FEATURE_ENABLED_FLAG";
export const SET_PAGINATION_FIX_ENABLED_FLAG = "SET_PAGINATION_FIX_ENABLED_FLAG";
export const SET_BULK_UPLOAD_FILE_READER_USING_XLSX_READER_ENABLED_FLAG = "SET_BULK_UPLOAD_FILE_READER_USING_XLSX_READER_ENABLED_FLAG";
export const SET_RESOURCE_REVIVAL_USING_ADD_BULK_RESOURCES_API_ENABLED_FLAG = "SET_RESOURCE_REVIVAL_USING_ADD_BULK_RESOURCES_API_ENABLED_FLAG";
export const SET_NARC_SSD_BIN_CREATION_ENABLED_FLAG = "SET_NARC_SSD_BIN_CREATION_ENABLED_FLAG";
export const SET_CURRENT_PAGE_TOKEN = "SET_CURRENT_PAGE_TOKEN";

export const NodeManagerReducer = (state, action) => {
    switch (action.type) {
        case BEGIN_CALL:
            return {
                ...state,
                loading: true,
                bulkResourceCreationModalState: false
            };
        case END_CALL_FOR_DOWNLOAD_RESOURCES:
            return {
                ...state,
                loading: false,
                bulkResourceCreationModalState: true
            };
        case BEGIN_CALL_FOR_VALIDATE_AND_DOWNLOAD_RESOURCE:
            return {
                ...state,
                loadingValidateAndDownloadResourceModal: true
            };
        case ERROR_CALL:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case CLEAR_RESOURCE_DATA:
            return {
                ...state,
                loading: true,
                resourceList: []
            };
        case CLEAR_RESOURCE_TYPE:
                return {
                    ...state,
                    loading: true,
                    resourceTypes: []
                };
        case TOGGLE_UPDATE_RESOURCE_MODAL:
            return {
                ...state,
                updateResourceModalState: action.status,
            };
        case GET_RESOURCE_DATA:
            return {
                ...state,
                resourceSearchCriteria: action.payload.resourceSearchCriteria,
                resourceTypes: action.payload.resourceTypes,
                currentNodeId: action.payload.currentNodeId,
                loading: action.payload.loading
            }
        case GET_RESOURCE_DATA_SUCCESS:
            const resourceList = action.payload.result.resources.map(
                resourceData => {
                    return {
                        ...resourceData,
                        open: false,
                        immediateChilds: {
                            resourceList: [],
                            displayFields: [],
                        }
                    };
                }
            )

            return {
                ...state,
                resourceSearchCriteria: action.payload.resourceSearchCriteria,
                resourcesByType: action.payload.result.resources,
                resourceList: resourceList,
                displayFields: action.payload.result.displayFields,
                deprecateResourceModalState: false,
                loading: false
            }

        case TOGGLE_VIEW_RESOURCE_MODAL:
            return {
                ...state,
                viewResourceModalState: action.status,
            };


        case UPDATE_RESOURCE_SUCCESS:
            let updatedResourcesList;
            updatedResourcesList = state.resourceList.map(resource =>
                resource.resourceId === state.currentResource.resourceId
                    ? {
                        ...state.currentResource,
                        immediateChilds : resource.immediateChilds
                    }
                    : resource
            );
            const updatedResourcesByType = state.resourcesByType.map(resource =>
                resource.resourceId === state.currentResource.resourceId
                    ? state.currentResource
                    : resource
            );
            return {
                ...state,
                loading: false,
                updateResourceModalState: true,
                viewResourceModalState: false,
                resourceList: updatedResourcesList,
                resourcesByType: updatedResourcesByType,
                updateType: TYPE.RESOURCE
            };

        case UPDATE_RESOURCE_RELATIONSHIP_SUCCESS:
            let updatedResourcesRelationshipList;
            const attributeMapping = FRONTEND_TO_BACKEND_ATTRIBUTES_MAPPING[state.currentResource.resourceType];
            const reverseAttributeMapping = reverseMapping(attributeMapping);
            updatedResourcesRelationshipList = state.resourceList.map(resource =>
                (resource[reverseAttributeMapping.resourceScannable] === (state.currentResource)[reverseAttributeMapping.resourceScannable]
                    &&
                    resource[reverseAttributeMapping.relatedResourceScannable] === (state.currentResource)[reverseAttributeMapping.relatedResourceScannable]
                )
                    ? {
                        ...state.currentResource,
                        immediateChilds : resource.immediateChilds
                    }
                    : resource
            );
            
            const updatedResourceRealtionshipsByType = state.resourcesByType.map(resource =>
                (resource[reverseAttributeMapping.resourceScannable] === (state.currentResource)[reverseAttributeMapping.resourceScannable]
                    &&
                    resource[reverseAttributeMapping.relatedResourceScannable] === (state.currentResource)[reverseAttributeMapping.relatedResourceScannable]
                )
                    ? state.currentResource
                    : resource
            );
            return {
                ...state,
                loading: false,
                updateResourceModalState: true,
                viewResourceModalState: false,
                resourceList:  updatedResourcesRelationshipList,
                resourcesByType: updatedResourceRealtionshipsByType,
                updateType: TYPE.ASSOCIATION
            };

        case UPDATE_RESOURCE_DATA:
            return {
                ...state,
                currentResource: {
                    ...state.currentResource,
                    [action.fieldName] : action.fieldValue,
                    resourceAttributes : {
                        ...state.currentResource.resourceAttributes,
                        [action.fieldName]: action.fieldValue
                    }
                }
            };

        case SET_CURRENT_RESOURCE:
            const currentResource = state.resourcesByType.filter(resource => resource.resourceId === action.resourceId).shift();
            return {
                ...state,
                loading: false,
                viewResourceModalState: true,
                currentResource: currentResource
            };
        case SET_CURRENT_RESOURCE_ASSOCIATION:
                const currentResourceAssociation = state.resourcesByType.filter(
                    resource => resource[DEPRECATE_ASSOCIATION_MODAL_ATTRIBUTES[resource.resourceType][0]] === action.resourceLabel && resource[DEPRECATE_ASSOCIATION_MODAL_ATTRIBUTES[resource.resourceType][1]] === action.relatedResourceLabel
                    ).shift();
                return {
                    ...state,
                    loading: false,
                    viewResourceModalState: true,
                    currentResource: currentResourceAssociation
            };    
        case SET_CURRENT_RESOURCE_TAB:
            return {
                ...state,
                loading: false,
                currentTab: action.tab
            };
        case UPDATE_RESOURCE_FAILURE:
            return {
                ...state,
                loading: false,
                updateResourceError: action.payload.error,
                viewResourceModalState: false,
                updateResourceModalState: true,
                updateType: TYPE.RESOURCE
            };

            case UPDATE_RESOURCE_RELATIONSHIP_FAILURE:
                return {
                    ...state,
                    loading: false,
                    updateResourceError: action.payload.error,
                    viewResourceModalState: false,
                    updateResourceModalState: true,
                    updateType: TYPE.ASSOCIATION
                };

        case TOGGLE_DEPRECATE_RESOURCE_MODAL:
            return {
                ...state,
                deprecateResourceModalState: action.payload.deprecateResourceModalState,
                deprecationResource: action.payload.deprecationResource,
                loading: false
            };
        case TOGGLE_DEPRECATE_RESOURCE_ASSOCIATION_MODAL:
            return {
                ...state,
                deprecateResourceModalState: action.payload.deprecateResourceModalState,
                deprecationResource: action.payload.deprecationResource,
                childResource: action.payload.childResource,
                loading: false
        };
        case CONTRACT_RESOURCE:
            const updatedResourceList = action.payload.resourceList.map(resource =>
                resource.resourceId === action.payload.resourceId
                    ? { ...resource, open: !resource.open }
                    : resource
            );
            return {
                ...state,
                resourceList: updatedResourceList,
                loading: action.payload.loading
            }
        case GET_IMMEDIATE_CHILDREN_DATA_SUCCESS:
            const resourceListForImmediateChild = action.payload.resourceList.map(resource =>
                resource.resourceId === action.payload.resourceId
                    ? {...resource, immediateChilds: {
                            ...resource.immediateChilds,
                            resourceList: action.payload.result.resources,
                            displayFields: action.payload.result.displayFields
                        }, open: !resource.open}
                    : resource
            );
            return {
                ...state,
                resourceList: resourceListForImmediateChild,
                loading: false
            }
        case UPDATE_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload.activeTab
            }

        case UPDATE_COUNTRY_CODE:
            return {
                ...state,
                countryCode: action.countryCode
            };

        case UPDATE_BULK_RESOURCE_MODAL_STATE:
            return {
                ...state,
                bulkResourceCreationModalState: action.payload.status,
                uploadBulkResourceError: ""
            }
        case VALIDATE_AND_DOWNLOAD_RESOURCE_MODAL_STATE:
            return {
                ...state,
                validateAndDownloadResourceModalState: action.payload.status,
                validateAndDownloadResourceError: ""
            }
        case CONCLUDE_VALIDATE_AND_DOWNLOAD_RESOURCE_MODAL_STATE:
            return {
                ...state,
                validateAndDownloadResourceModalState: action.payload.status,
                validateAndDownloadResourceError: "",
                generateValidationReportErrorMessage: "",
                generateValidationReportSuccessMessage: "",
                aisleResources: [],
                aisleToStagingZoneDistanceResources: [],
                loadingValidateAndDownloadResourceModal: false,
                aisleToStagingZoneDistanceValidationEnabled: false
            }
        case CONCLUDE_BULK_RESOURCE_CREATION_MODAL:
            return {
                ...state,
                bulkResourceCreationModalState: action.payload.status,
                uploadBulkResourceSuccess: false,
                resourceDataMap: {}
            }
        case READ_RESOURCE_FILE_FAILURE:
            return {
                ...state,
                uploadBulkResourceError: action.payload.uploadBulkResourceError,
                loadingFile: false,
                bulkResourceCreationModalState: true
            }
        case VALIDATE_RESOURCE_FAILURE:
            return {
                ...state,
                validateAndDownloadResourceError: action.payload.validateAndDownloadResourceError,
                loadingValidateAndDownloadResourceModal: false
            }
        case GENERATE_VALIDATION_REPORT_START:
            return {
                ...state,
                generateValidationReportErrorMessage: "",
                generateValidationReportSuccessMessage: "",
                loadingValidateAndDownloadResourceModal: true
            }
        case GENERATE_VALIDATION_REPORT_FAILURE:
            return {
                ...state,
                generateValidationReportErrorMessage: action.payload.generateValidationReportErrorMessage,
                loadingValidateAndDownloadResourceModal: false
            }
        case GENERATE_VALIDATION_REPORT_SUCCESS:
            return {
                ...state,
                generateValidationReportErrorMessage: "",
                generateValidationReportSuccessMessage: action.payload.generateValidationReportSuccessMessage,
                loadingValidateAndDownloadResourceModal: false
            }
        case VALIDATE_AISLE_TO_STAGING_ZONE_DISTANCE_RESOURCE_SUCCESS:
            return {
                ...state,
                aisleResources: action.payload.aisleResources,
                aisleToStagingZoneDistanceResources: action.payload.aisleToStagingZoneDistanceResources,
                loadingValidateAndDownloadResourceModal: false,
                aisleToStagingZoneDistanceValidationEnabled: true
            }
        case DOWNLOAD_AISLE_TO_STAGING_ZONE_DISTANCE_RESOURCE_SUCCESS:
            return {
                ...state,
                aisleResources: action.payload.aisleResources,
                aisleToStagingZoneDistanceResources: action.payload.aisleToStagingZoneDistanceResources,
                loadingValidateAndDownloadResourceModal: false
            }
        case DOWNLOAD_RESOURCES_SUCCESS:
            return {
                ...state,
                loadingValidateAndDownloadResourceModal: false
            }
        case UPDATE_RESOURCE_SEARCH_CRITERIA:
            return {
                ...state,
                resourceSearchCriteria: action.payload.resourceSearchCriteria
            }
        case READ_RESOURCE_FILE_SUCCESS:
            return {
                ...state,
                resourceDataMap: action.payload.resourceDataMap,
                resourceNames: action.payload.resourceNames,
                activeTab: action.payload.activeTab,
                loadingFile: false,
                bulkResourceCreationModalState: true
            }
        case BULK_UPLOAD_RESOURCE:
            return {
                ...state,
                resourceDataMap: {},
                loading: false,
                bulkResourceCreationModalState: true,
                uploadBulkResourceError: action.payload.uploadBulkResourceError,
                uploadBulkResourceSuccess: action.payload.uploadBulkResourceSuccess,
                uploadType: action.payload.uploadType,
                loadingFile: false
            }
        case CLEAR_RESOURCE_DATAMAP:
            return {
                ...state,
                resourceDataMap: {}
            }
        case DEPRECATE_RESOURCE_SUCCESS:
            return {
                ...state,
                deprecateResourceModalState: false
            }
        case DEPRECATE_RESOURCE_FAILURE:
            return {
                ...state,
                deprecateResourceModalState: false,
                updateResourceError: action.payload.updateResourceError,
                updateResourceModalState: true
            }
        case ACTION_TYPE.GET_FEATURES_STATUS:
            return {
                ...state,
                narc_sls_intergration_enabled: action.narc_sls_intergration_enabled
            }
        case ACTION_TYPE.GET_FEATURES_STATUS_FOR_NARC_AUTHORIZATION_CHECK:
            return {
                ...state,
                isNARCTCPAuthorizationCheckEnabled: action.isNARCTCPAuthorizationCheckEnabled
            }
        case ACTION_TYPE.GET_FEATURES_STATUS_FOR_SSD_AUTHORIZATION:
            return {
                ...state,
                narc_ssd_authorization_enabled: action.narc_ssd_authorization_enabled
            }
        case ACTION_TYPE.IS_SSD_MMOT_ENABLED:
            return {
                ...state,
                isSSDMMOTEnabled: action.isSSDMMOTEnabled
            }
        case  SET_IS_VALID_EDIT_MODAL:
            return {
                ...state,
                isValidEditModal: action.payload.isValidEditModal,
                isValidEditModalMessage: action.payload.isValidEditModalMessage
            }
        case SET_IS_AUTHORIZED_USER:
            return {
                ...state,
                isAuthorizedUser: action.payload.isAuthorizedUser
            }
        case BEGIN_BULK_EXCEL_PARSING:
            return {
                ...state,
                loadingFile: true,
                loaderType : action.payload.loaderType
            };
        case SET_OVD_FEATURE_ENABLED_FLAG:
            return {
                ...state,
                isOVDFeatureEnabled : action.payload.isOVDFeatureEnabled
            };
        case SET_PAGINATION_FIX_ENABLED_FLAG:
            return {
                ...state,
                isPaginationFixEnabled : action.payload.isPaginationFixEnabled
            };
        case SET_BULK_UPLOAD_FILE_READER_USING_XLSX_READER_ENABLED_FLAG:
            return {
                ...state,
                isBulkUploadReaderUsingXLSXReaderEnabled : action.payload.isBulkUploadReaderUsingXLSXReaderEnabled
            };
        case SET_RESOURCE_REVIVAL_USING_ADD_BULK_RESOURCES_API_ENABLED_FLAG:
            return {
                ...state,
                isResourceRevivalUsingAddBulkResourcesAPIEnabled : action.payload.isResourceRevivalUsingAddBulkResourcesAPIEnabled
            };
        case SET_NARC_SSD_BIN_CREATION_ENABLED_FLAG:
            return {
                ...state,
                isNARCSSDBinCreationEnabled : action.payload.isNARCSSDBinCreationEnabled
            };
        default:
            return state
    }
}
